/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider.state('speedy-rewards', {
    parent: 'root',
    url: '/speedy-rewards',
    templateUrl: 'apps/speedy-rewards/templates/acp-speedy-rewards.ng.html',
    resolve: {
      speedyV2Redirect: speedyV2Redirect,
      module: /* @ngInject */ function($ocLazyLoad) {
        return import(/* webpackChunkName: "acp.section.speedy-rewards" */ 'apps/speedy-rewards').then(
          $ocLazyLoad.loadModule
        );
      }
    },
    data: {
      permissions: {
        only: 'speedyrewards_eligible',
        redirectTo: {
          speedyrewards_eligible: 'dashboard'
        }
      }
    }
  });
}

// @ngInject
function speedyV2Redirect(
  module,
  $q,
  $state,
  acpSpeedyRewardsModel,
  nsPermissions
) {
  return $q
    .all({
      isSpeedyRewardsV1Enrolled: acpSpeedyRewardsModel.isEnrolled(),
      isSpeedyRewardsV2Eligible: nsPermissions.requestPermission(
        'isPointsEligible'
      )
    })
    .then(function(data) {
      if (!data.isSpeedyRewardsV1Enrolled && data.isSpeedyRewardsV2Eligible) {
        return $state.go('points.enrollment');
      }
    });
}

export default states;
